/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components/macro"

import Header from "../header"
import Footer from "../Footer"
import Container from "../common/Container"
import Seo from "../seo"
import GradientLine from "../gradient-line"
import PostPreview, { PostExcerpt } from "../post"
import { pxToRem } from "../../utils"
import Up from "../Up"
import CookieConsent from "../CookieConsent"

const Main = styled.main`
  /* background-color: #1a1a1a; */
  background-color: #000;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 30px 0 100px 0;

  transition: all 0.2s ease-in-out;
  transform: none;

  &.is-open {
    transform: translate(-275px);
  }
`
const Wrapper = styled.div`
  &.is-open {
    overflow: hidden;
    height: 100vh;
  }
`
const Overlay = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  width: calc(100% - 275px);

  &.is-open {
    visibility: visible;
    position: absolute;
    top: 85px;
    right: 0;
    left: 0;
    bottom: 0;
  }
`
const AltTitleContainer = styled.header`
  text-align: center;
  margin-bottom: 3rem;
  @media all and (min-width: 768px) {
    margin-bottom: 5.5555556rem;
  }
`
const Title = styled.h1`
  font-family: "Teko", sans-serif !important;
  color: #fff !important;
  font-size: 4.125rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 3.125rem;
`
const SecondaryTitle = styled.h2`
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.2;
  color: #fff !important;
  margin-top: 0;
  margin-bottom: 2.77777778rem;
  word-break: break-word;

  @media all and (min-width: 821px) {
    font-size: 3.125rem;
  }
`
const BlogContainer = styled(Container)`
  margin: 0 auto 100px auto;
`
const Article = styled.article`
  .gatsby-resp-image-wrapper {
    margin: 30px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: #6c9fff;
    font-family: "Montserrat", sans-serif;
  }

  a {
    text-decoration: none;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
  .gatsby-resp-iframe-wrapper {
    margin-top: 1.45rem;
    margin-bottom: 1.45rem;
  }
`
const ButtonContainer = styled.div`
  margin-top: 3.125rem;
  text-align: center;
`
const Button = styled.a``
const StyledButton = styled(Button)`
  display: block;
  font-size: 2rem;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
`
const Time = styled.time`
  font-size: 1.25rem;
  font-weight: 300;
  margin-bottom: 3.125rem;
  display: block;
`
const Breadcrumb = styled(Container)`
  margin-bottom: 3.125rem;
`
const BreadcrumbLink = styled(Link)`
  font-weight: 300;
  font-size: 1rem;
`
const Excerpt = styled.p`
  margin-top: 2rem;
  font-size: 1.75rem;
  margin-bottom: 3.125rem;
  text-align: left;
`
const KeepOnReadingWrapper = styled.div`
  margin-top: ${pxToRem(100)};
`
const KeepOnReadingTitle = styled.h2`
  font-family: "Teko", sans-serif !important;
  color: #fff !important;
  font-size: 3.5rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1.5rem;
`
const KeepOnReadingGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  ${PostExcerpt} {
    display: flex;
  }

  @media all and (min-width: 821px) {
    grid-template-columns: 1fr 1fr;
  }
`

const PostPageLayout = ({
  data: {
    current: { frontmatter, html },
    next,
    prev,
  },
}) => {
  const [isOpen, setMenu] = React.useState(false)
  const toggleMenu = () => setMenu(!isOpen)
  const {
    slug,
    title,
    publishDate,
    buttonURL,
    type,
    excerpt,
    cover,
    coverAlt,
    buttonLabel,
  } = frontmatter
  const morePosts = [...(next ? [next] : []), ...(prev ? [prev] : [])]
  return (
    <Wrapper className={isOpen && "is-open"}>
      <CookieConsent />
      <Header isOpen={isOpen} menuHandler={toggleMenu} />
      <GradientLine />
      <Main className={isOpen && "is-open"}>
        {type !== "legal" ? (
          <Breadcrumb>
            <BreadcrumbLink to="/">Home</BreadcrumbLink> {` >> `}
            <BreadcrumbLink to="/blog">Blog</BreadcrumbLink>
            {` >> `}
            <BreadcrumbLink to={slug} activeStyle={{ fontWeight: 700 }}>
              {title}
            </BreadcrumbLink>
          </Breadcrumb>
        ) : null}
        <BlogContainer>
          <Article>
            {type !== "legal" ? (
              <AltTitleContainer>
                <Title className="wow animate__fadeIn">Blog</Title>
                <SecondaryTitle
                  className="wow animate__fadeIn"
                  data-wow-delay=".2s"
                >
                  {title}
                </SecondaryTitle>
                <Time
                  className="wow animate__fadeIn"
                  data-wow-delay=".4s"
                  dateTime={publishDate}
                >
                  {new Date(publishDate).toDateString()}
                </Time>
                <GatsbyImage
                  image={getImage(cover.src)}
                  alt={coverAlt}
                  title={coverAlt}
                />
                <Excerpt>{excerpt}</Excerpt>
              </AltTitleContainer>
            ) : null}
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Article>
          {buttonURL ? (
            <ButtonContainer>
              <StyledButton to={buttonURL}>
                {buttonLabel || "Ajánlatkérés"}
              </StyledButton>
            </ButtonContainer>
          ) : null}
          {type !== "legal" ? (
            <KeepOnReadingWrapper>
              <KeepOnReadingTitle>Keep on reading!</KeepOnReadingTitle>
              <KeepOnReadingGrid>
                {morePosts.map(
                  ({ frontmatter: { cover, title, excerpt, slug } }, idx) => (
                    <PostPreview
                      key={idx}
                      cover={cover.src.gatsbyImageData.images.fallback.src}
                      title={title}
                      excerpt={excerpt}
                      slug={slug}
                    />
                  )
                )}
              </KeepOnReadingGrid>
            </KeepOnReadingWrapper>
          ) : null}
        </BlogContainer>
      </Main>
      <GradientLine />
      <Footer />
      <Overlay className={isOpen && "is-open"} onClick={toggleMenu} />
      <Up />
    </Wrapper>
  )
}

export default PostPageLayout

export const Head = ({
  data: {
    current: { frontmatter },
  },
}) => {
  return (
    <Seo
      title={frontmatter.seoTitle}
      description={frontmatter.seoMetaDescription}
      ogImage={frontmatter.cover.src.resize.src}
      slug={frontmatter.slug}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String, $nextId: String, $prevId: String) {
    current: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        slug
        seoTitle
        seoMetaDescription
        excerpt
        cover {
          src: childImageSharp {
            gatsbyImageData(formats: [WEBP])
            resize(width: 900) {
              src
            }
          }
        }
        coverAlt
        type
        buttonURL
        buttonLabel
        publishDate
      }
    }
    next: markdownRemark(id: { eq: $nextId }) {
      id
      frontmatter {
        slug
        title
        excerpt
        coverAlt
        cover {
          src: childImageSharp {
            gatsbyImageData(formats: [WEBP])
            resize(width: 900) {
              src
            }
          }
        }
      }
    }
    prev: markdownRemark(id: { eq: $prevId }) {
      id
      frontmatter {
        slug
        title
        excerpt
        coverAlt
        cover {
          src: childImageSharp {
            gatsbyImageData(formats: [WEBP])
            resize(width: 900) {
              src
            }
          }
        }
      }
    }
  }
`
